<script>
export default {
  name: 'MediaPreview',
  props: {
    mediaUrl: {
      type: String,
      default: null
    }
  },

  computed: {
    getEmbededUrl () {
      if (this.isYoutube()) {
        return this.getYoutubeEmbedUrl()
      }

      if (this.isVimeo()) {
        return this.getVimeoEmbedUrl()
      }

      return null
    }
  },

  methods: {
    isYoutube () {
      const YOUTUBE_URL_ROOT = 'youtube.com'

      return this.mediaUrl?.indexOf(YOUTUBE_URL_ROOT) > 0
    },

    isVimeo () {
      const VIMEO_URL_ROOT = 'vimeo.com'

      return this.mediaUrl?.indexOf(VIMEO_URL_ROOT) > 0
    },

    getYoutubeEmbedUrl () {
      const url = this.mediaUrl

      const match = url.match(/(?:v=|\/)([\w-]{11})(?:\?|&|$)/)
      const videoId = match ? match[1] : null

      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}/?modestbranding=1&fs=0&showinfo=0&rel=0`
      }

      return null
    },

    getVimeoEmbedUrl () {
      const url = this.mediaUrl

      const match = url.match(/^https:\/\/(player\.)?vimeo\.com\/(video\/)?([\w]+)(\/[\w]*)?$/)
      const videoId = match && undefined !== match[3] ? match[3] : null

      if (videoId) {
        return 'https://player.vimeo.com/video/' + videoId
      }

      return null
    }
  }
}
</script>

<template>
  <iframe
    v-if="getEmbededUrl"
    id="mediaPreview"
    class="media-preview"
    type="text/html"
    width="480"
    height="183"
    :src="getEmbededUrl"
    frameborder="0"
  />
</template>

<style lang="scss" src="@/assets/styles/themes/default/mediaPreview.scss"></style>
